<template>
  <div class="card chart-gradient" style="height:30em; color:white;">
    <div class="card-header">Krysspeiling, andel</div>
    <div class="card-body">
      <div ref="chart"></div>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';

export default {
  name: "TriangulationStackedChart",
  props: {
    meteors: {
      type: Array,
    },
  },
  data() {
    return {
      chart: null,
      totalCategoryCounts: [],
      chartOptions: {

        theme: {
          mode: 'dark', 
          palette: 'palette10',          
        }, 
        chart: {
          type: 'bar',
          stacked: true,
          width: "100%",
          height: "90%",
          background: 'transparent',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
          // Display count and percentage in data labels
          formatter: function (val, opts) {
            const count = Math.round((val / 100) * this.totalCategoryCounts[opts.dataPointIndex]);
            return count + ` (${Math.round(val)}%)`;
          }.bind(this),
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          max: 100,
          labels: {
            formatter: function (value) {
              return Math.round(value) + '%';
            },
          },
        },
        legend: {
          showForSingleSeries: true,
          labels: {
            colors: ['#FFFFFF'],
            useSeriesColors: false
          },
        },
        series: [
          {
            name: "",
            data: [],
          },
        ],
        colors: ['#648FFF', '#785EF0', '#DC267F', '#FE6100', '#FFB000'],
      },

      series: [],
    };
  },
  methods: {
    // Process data to create the series and categories for the chart
    processData(data) {
      const counts = {};

      // Count occurrences of meteors for each month and station combination
      data.forEach((entry) => {
        const date = new Date(entry.date);
        const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

        if (!counts[month]) {
          counts[month] = {};
        }

        const numberOfStations = entry.NumberOfStations;

        if (!counts[month][numberOfStations]) {
          counts[month][numberOfStations] = 0;
        }

        counts[month][numberOfStations]++;
      });

      // Generate sorted categories and calculate total counts for each category
      const categories = Object.keys(counts).sort();
      this.totalCategoryCounts = categories.map((month) =>
        Object.values(counts[month]).reduce((a, b) => a + b, 0)
      );

      // Create series data from counts
      const seriesData = {};
      categories.forEach((month) => {
        Object.entries(counts[month]).forEach(([numberOfStations, count]) => {
          if (!seriesData[numberOfStations]) {
            seriesData[numberOfStations] = Array(categories.length).fill(0);
          }
          const monthIndex = categories.indexOf(month);
          seriesData[numberOfStations][monthIndex] = count;
        });
      });

      // Create the final series for the chart
      const series = Object.entries(seriesData).map(([numberOfStations, data]) => {
        let seriesName;
        if (numberOfStations === '1') {
          seriesName = 'Ingen krysspeiling';
        } else {
          seriesName = `Krysspeiling mellom ${numberOfStations} stasjoner`;
        } return {
          name: seriesName,
          data: data.map((count, index) => {
            const totalCount = this.totalCategoryCounts[index];
            return (count / totalCount) * 100;
          }),
        };
      });

      this.chartOptions.xaxis.categories = categories;
      this.series = series;
    },

    // Update the chart with new data
    updateChart() {
      if (this.chart) {
        this.chart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories,
          },
          series: this.series,
        });
      } else {
        this.chart = new ApexCharts(this.$refs.chart, {
          ...this.chartOptions,
          series: this.series,
        });
        this.chart.render();
      }
    },
  },
  watch: {
    meteors(newMeteors) {
      this.processData(newMeteors);
      this.updateChart();
    },
  },
  mounted() {
    this.processData(this.meteors);
    this.updateChart();
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped>
.apexcharts-menu {
  background-color: #FFFFFF !important;
  color: #FFFFFF !important;
  border: 0px;
}

.apexcharts-toolbar {
  background-color: #FFFFFF !important;
  color: #FFFFFF !important;
  border: 0px;
}
</style>

       
