<template>
  <div class="row">
    <div v-for="(chart, index) in charts" :key="index" class="col-md-4" >
      <div class="card text-center flex-fill chart-gradient" >
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="card-title" style="color:white;">{{ chart.title }}</div>
            </div>
            <div class="col-auto">
              <span class="chart-description " style="color:white;" data-bs-toggle="tooltip" data-bs-placement="top" :title="chart.description">
                <i class="fas fa-info-circle"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="card-body p-0" style="color:white;">
          <div class="text-center">
            <h1 class="display-4" >{{ chart.trend }}</h1>            
          </div>
          <div>
            <apexchart :type="chart.options.chart.type || 'line'" :options="chart.options" :series="chart.series"
              ref="chartRefs" :data-index="index"   width="100%" height="50%"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import VueApexCharts from "vue3-apexcharts";
import { groupByYearMonth } from "@/components/helpers/helpers.js";
import { Tooltip } from 'bootstrap';




export default {
  components: {
    apexchart: VueApexCharts,

  },


  props: {
    coordinates: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      charts: [],
    };
  },

  watch: {
    coordinates: {
      deep: true,
      immediate: true,
      handler() {
        this.updateCharts();
      },
    },
  },

  mounted() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })
  },

  methods: {
    updateCharts() {
      if (!this.coordinates || this.coordinates.length === 0) {
        this.charts = [];
        return;
      }

      const meteors = this.coordinates;
      const crossReferencedMeteors = this.coordinates.filter(
        (meteor) =>
          meteor.track_endheight != null &&
          meteor.track_speed != null &&
          meteor.ProperTriangulation == "1"
      );
      const meteoriteCandidates = this.coordinates.filter(
        (meteor) =>
          meteor.track_endheight != null &&
          meteor.track_endheight < 25 &&
          meteor.track_speed != null &&
          meteor.track_speed < 25 &&
          meteor.ProperTriangulation == "1"
      );

      const meteorsByYearMonth = groupByYearMonth(meteors); // Use the groupByYearMonth from helper file
      const crossReferencedMeteorsByYearMonth = groupByYearMonth(crossReferencedMeteors); // Use the groupByYearMonth from helper file
      const meteoriteCandidatesByYearMonth = groupByYearMonth(meteoriteCandidates); // Use the groupByYearMonth from helper file

      const meteorsCategories = Object.keys(meteorsByYearMonth).sort();
      const crossReferencedCategories = Object.keys(crossReferencedMeteorsByYearMonth).sort();
      const meteoriteCandidatesCategories = Object.keys(meteoriteCandidatesByYearMonth).sort();

      if (
        meteorsCategories.length === 0 ||
        crossReferencedCategories.length === 0 ||
        meteoriteCandidatesCategories.length === 0
      ) {
        this.charts = [];
        return;
      }

      this.charts = [
        {
          title: "Meteorer",
          description: 'Alle observasjoner av meteorer, sammensatt av en ellere flere registreringer',
          trend: meteors.length,
          series: [
            {
              name: "Meteorer",
              data: Object.values(meteorsByYearMonth).reverse(),
            },
          ],
          options: this.commonOptions(Object.keys(meteorsByYearMonth).reverse()),
        },
        {
          title: "Krysspeilede",
          description: 'Antall meteorer som har to eller flere registreringer fra forskjellige stasjoner',
          trend: crossReferencedMeteors.length,
          series: [
            {
              name: "Krysspeilede",
              data: Object.values(crossReferencedMeteorsByYearMonth).reverse(),
            },
          ],
          options: this.commonOptions(Object.keys(crossReferencedMeteorsByYearMonth).reverse()),
        },
        {
          title: "Meteorittkandidater",
          description: 'Antall meteorer som er krysspeilet, hastighet under 25km/s, samt observert lavere enn 25km høyde over havet',
          trend: meteoriteCandidates.length,
          series: [
            {
              name: "Meteorittkandidater",
              data: Object.values(meteoriteCandidatesByYearMonth).reverse(),
            },
          ],
          options: this.commonOptions(Object.keys(meteoriteCandidatesByYearMonth).reverse()),
        },
      ];
    },

    commonOptions(categories) {
      return {
        theme: {
          mode: 'dark', 
          palette: 'palette10',          
        },    
        chart: {         
          sparkline: {
            enabled: true,
          },    
          type: "area",
          background: 'transparent',
          toolbar: {
            show: true,
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              download: true,
            },
          },
        },
        title: {
          style: {
            fontSize: "20px",
            /* fontFamily: "Arial, sans-serif", */
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
        },
      };
    },
  },
};
</script>


<style scoped>

.chart-description {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  cursor: pointer;
}



.sparkboxes .box1 {
  background-image: linear-gradient( 135deg, hsl(231, 40%, 31%) 10%, #021625 100%); 
}

.sparkboxes .box2 {
  background-image: linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%);
}

.sparkboxes .box3 {
  background-image: linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%);
}

.sparkboxes .box4 {
  background-image: linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%);
}
</style>
