<template>
  <div class="card h-100  chart-gradient" style="color:white;">
    <div class="card-header">Krysspeiling, andel</div>
    <div class="card-body">
      <div ref="chart"></div>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';

export default {
  name: "TriangulationDonutChart",
  props: {
    meteors: {
      type: Array,
    },
  },
  data() { 
    return {
      chart: null,
      chartOptions: {
        chart: {
          type: 'donut',
          width: "90%",
          height: "100%",
        },
        plotOptions: {
          pie: {
            customScale: 1
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return `${val.toFixed(1)}%`;
          }.bind(this),
        },
        legend: {
          showForSingleSeries: true,
          position: 'bottom',
          labels: {
            colors: ['#FFFFFF'],
            useSeriesColors: false
          },
        },
        series: [],
        labels: [],

        theme: {
          /*  mode: 'dark', */
          palette: 'palette10',          
        },
        /* colors: ['#648FFF', '#785EF0', '#DC267F', '#FE6100', '#FFB000'], */
      },

      series: [],
    };
  },
  methods: {
    processData(data) {
      const counts = {};

      data.forEach((entry) => {
        const numberOfStations = Number(entry.NumberOfStations);

        if (!counts[numberOfStations]) {
          counts[numberOfStations] = 0;
        }

        counts[numberOfStations]++;
      });

      const series = Object.values(counts);
      const labels = Object.keys(counts).map((numberOfStations) => {
        let label;
        if (numberOfStations === '1') {
          label = 'Ingen krysspeiling';
        } else {
          label = `Krysspeiling mellom ${numberOfStations} stasjoner`;
        }
        return label;
      });

      this.series = series;
      this.chartOptions.labels = labels;
    },

    updateChart() {
      if (this.chart) {
        this.chart.updateOptions({
          labels: this.chartOptions.labels,
          series: this.series,
        });
      } else {
        this.chart = new ApexCharts(this.$refs.chart, {
          ...this.chartOptions,
          series: this.series,
        });
        this.chart.render();
      }
    },
  },
  watch: {
    meteors(newMeteors) {
      this.processData(newMeteors);
      this.updateChart();
    },
  },
  mounted() {
    this.processData(this.meteors);
    this.updateChart();
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped>
.apexcharts-menu {
  background-color: #FFFFFF !important;
  color: #FFFFFF !important;
  border: 0px;
}

.apexcharts-toolbar {
  background-color: #FFFFFF !important;
  color: #FFFFFF !important;
  border: 0px;
}

.apexcharts-tooltip span {
  color: #ffffff;
}
</style>
