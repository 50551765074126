<template>
  <div>
    <!-- First row of dashboard reserved for showing KPI boxes-->
    <div class="row mb-3">
      <KPIPanel :coordinates="coordinates" />
    </div>
    <!-- Second row of dashboard with secondary information, showing charts-->
    <div class="row mb-3">
      <div class="col-lg-4" style="height:30rem">
        <!-- Krysspeiling donut chart -->
        <TriangulationDonutChart v-if="chartReady" :meteors="coordinates" />
      </div>
      <div class="col-lg-8">
        <!-- Krysspeiling stacked chart -->
        <TriangulationStackedChart v-if="chartReady" :meteors="coordinates" />
      </div>
    </div>
  </div>
</template>

<script>
import KPIPanel from '@/components/visualization/KPIPanel.vue';
import TriangulationDonutChart from '@/components/visualization/TriangulationDonutChart.vue';
import TriangulationStackedChart from '@/components/visualization/TriangulationStackedChart.vue';
import { mapGetters } from "vuex";
export default {
  name: 'Nokkeltall',

  components: {
    KPIPanel,
    TriangulationDonutChart,
    TriangulationStackedChart
  },

  computed: {
    ...mapGetters("dashboard", ["coordinates", "chartReady"]),
  },

  created() {
    // Access query parameters from the URL
    //const { from_date, to_date, stations } = this.$route.query;
    // Do something with the query parameters - TBD

  },


  data() {
    return {

    };
  },

}
</script>