export function downloadCSV(header, data, filename) {
  const csvContent = [header, ...data].map(row => row.join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * getCameraStationData - Retrieves camera station data, including station and camera names, and image service links.
 *
 * This function returns an array of camera station objects. Each object contains the following properties:
 * - station: The name of the camera station.
 * - cameras: An array of camera objects, where each camera object has the following properties:
 *   - name: The name of the camera.
 *   - imgService: The image service link for the camera.
 *
 * Note: This function currently uses hard-coded list of stations and cameras.
 *
 * @returns {Array<Object>} An array of camera station objects containing station and camera data.
 *
 * Example usage:
 * const cameraData = getCameraStationData();
 * console.log(cameraData);
 */
export async function getLastSeenCameraStations() {
  const API_URL = 'https://ildkule.net/api/stationlog';

  try {
    const response = await fetch(API_URL);
    if (response.ok) {
      const cameraStations = await response.json();
      return cameraStations;
    } else {
      throw new Error(`Failed to fetch camera stations data: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching camera stations data:', error);
    throw error;
  }
}



/**
* Calculates the elapsed time since a given date in a human-readable format.
* @param {string} lastSeen - The date in ISO format to compare against the current date.
* @returns {string} A string representing the elapsed time in days and hours.
*/
export function elapsedTime(lastSeen) {
  const elapsedMs = Date.now() - new Date(lastSeen).getTime();
  const elapsedDays = Math.floor(elapsedMs / (1000 * 60 * 60 * 24));
  const elapsedHours = Math.floor((elapsedMs / (1000 * 60 * 60)) % 24);
  return `${elapsedDays} days, ${elapsedHours} hours ago`;
}

/**
* Calculate the time since the given timestamp and return it as a formatted string.
* @param {number} timestamp - The timestamp to calculate the time since.
* @returns {string} - Formatted string with days and hours since the timestamp.
*/
export function timeSinceLastSeen(timestamp) {
  if (!timestamp) {
    return 'N/A';
  }
  const days = Math.floor((Date.now() - timestamp) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((Date.now() - timestamp) / (1000 * 60 * 60)) % 24;

  return `${days} dager, ${hours} timer siden`;
}




/**
 * Calculates the difference between the passed-in timestamp and the current time. 
 * Returns an object with two keys: days and hours. 
 * 
*/
export function lastSeen(timestamp) {
  if (!timestamp) {
    return { days: 'N/A', hours: 'N/A' };
  }
  const days = Math.floor((Date.now() - timestamp) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((Date.now() - timestamp) / (1000 * 60 * 60)) % 24;

  return { days, hours };
}



/**
  * Get the latest timestamp among the cameras of a given station.
  * @param {Object} station - The station object containing cameras.
  * @returns {number} - The latest timestamp among the cameras.
  */
export function getLastSeenTime(station) {
  if (!station || !Array.isArray(station.cameras)) {
    console.error('getLastSeenTime was called with invalid data:', station);
    return;  // or return a default value
  }

  return station.cameras.reduce((latest, camera) => {
    const cameraTime = camera.lastSeen ? new Date(camera.lastSeen).getTime() : 0;
    return cameraTime > latest ? cameraTime : latest;
  }, 0);
}



/**
 * Get the appropriate CSS class based on the time passed since the last seen timestamp.
 * @param {number} lastSeen - The last seen timestamp.
 * @returns {string} - The appropriate CSS class.
 */
export function trafficLightStatus(lastSeen) {
  const hoursSinceLastSeen = (Date.now() - new Date(lastSeen).getTime()) / (1000 * 60 * 60);

  if (hoursSinceLastSeen <= 24) {
    return 'badge badge-pill badge-primary-custom';
  } else if (hoursSinceLastSeen > 24 && hoursSinceLastSeen <= 168) {
    return 'badge badge-pill badge-warning-custom';
  } else {
    return 'badge badge-pill badge-danger-custom';
  }
}

export function cameraTrafficLightStatus(lastSeen) {
  const minutesSinceLastSeen = (Date.now() - new Date(lastSeen).getTime()) / (1000 * 60);

  if (minutesSinceLastSeen > 60) {
    return 'badge badge-pill badge-danger-custom';
  } else {
    return 'badge badge-pill badge-primary-custom';
  }
}

export function stationTrafficLightStatus(cameras) {
  const camerasGone = cameras.filter(camera =>
    (Date.now() - new Date(camera.lastSeen).getTime()) / (1000 * 60) > 60
  ).length;

  if (camerasGone === cameras.length) {
    return 'badge badge-pill badge-danger-custom';
  } else if (camerasGone > 0) {
    return 'badge badge-pill badge-warning-custom';
  } else {
    return 'badge badge-pill badge-primary-custom';
  }
}



/**
   * Format a date string by removing all non-numeric characters and returning the first 14 characters.
   * 
   * @param {string} dateString - The date string to format.
   * @returns {string} - The formatted date string.
   */
export function stripDate(dateString) {
  return dateString.replace(/[^0-9]/g, '').substring(0, 14);
}

/**
 * Generate a video URL using the provided date time tag, camera data, and formatted date.
 * 
 * @param {string} dateTimeTag - The date time tag string.
 * @param {object} cam - The camera data object.
 * @param {string} formattedDate - The formatted date string.
 * @returns {string} - The generated video URL.
 */
export function generateVideoUrl(dateTimeTag, cam, formattedDate) {
  const baseUrl = 'https://ildkule.net/data/';
  const folderPath = `${dateTimeTag.substring(0, 8)}/${dateTimeTag.substring(8)}`;
  const camPath = `${cam.station.station_name}/${cam.cam_name}`;
  const fileName = `${cam.station.station_name}-${formattedDate}-gnomonic.mp4`;

  return `${baseUrl}${folderPath}/${camPath}/${fileName}`;
}

/**
 * Format the given date as a string in the format "YYYY-MM-DD".
 * @param {Date} date - The date to format.
 * @returns {string} - The formatted date string.
 */
export function formatDate(date) {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}



export function groupBy(array, keyFn) {
  return array.reduce((result, currentValue) => {
    const key = typeof keyFn === 'function' ? keyFn(currentValue) : currentValue[keyFn];
    (result[key] = result[key] || []).push(currentValue);
    return result;
  }, {});
}


export function mapValues(obj, fn) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, fn(value, key, obj)])
  );
}

export function groupByYearMonth(data) {
  const grouped = data.reduce((result, item) => {
    const date = new Date(item.date);
    if (isNaN(date)) {
      return result;
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const key = `${year}-${month}`;
    (result[key] = result[key] || []).push(item);
    return result;
  }, {});

  return mapValues(grouped, (items) => items.length);
}

